<template lang="pug">
  FormModal.modal-duplicate-collection(title="Duplicate Collection", :endpoint="`collections/${collection.id}/duplicate`", :data="data", v-bind="$attrs", @success="onSuccess")
    p.mb-8 You are about to duplicate #[strong {{ collection.name }}] to a new collection along with all of its content. Please choose which folder you would like the newly duplicated collection placed under, as well as a unique name.

    InputGroup(label="Privacy", name="type", v-if="user.hasPermission('collections.shared_manage') && user.hasPermission('collections.manage')")
      Radios(v-model="data.type", inline)
        Radio(value="partner", @change="onTypeChange()")
          Icon(icon="users")
          div
            div: strong Shared Collection
            div.option-label Shared among all members of your team.
        Radio(value="personal", @change="onTypeChange()")
          Icon(icon="user")
          div
            div: strong Personal Collection
            div.option-label Private and visible only to you.

    InputGroup(name="Name", label="name")
      TextInput(v-model="data.name", prepend-icon="collection", textarea, :rows="1", auto-focus)

    InputGroup(label="Folder", name="folder_id")
      Select(v-model="data.folder_id", :endpoint="`options/${data.type}/folders`", option-label="label", prepend-option="No Folder", prepend-icon="folder", prepend-icon-prefix="fas")

    InputGroup(label="Internal code — optional", name="code")
      TagsInput(v-model="data.code", prepend-icon="tag")
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: {
    collection: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  data: () => ({
    data: {
      type: null,
      name: "",
      folder_id: null,
      code: []
    }
  }),
  created() {
    if (this.type === "partner" && this.user.hasPermission("collections.shared_manage")) {
      this.data.type = "partner"
    } else if (this.type === "personal" && this.user.hasPermission("collections.manage")) {
      this.data.type = "personal"
    } else if (this.user.hasPermission("collections.shared_manage") && this.user.hasPermission("collections.manage")) {
      this.data.type = "partner"
    } else if (this.user.hasPermission("collections.shared_manage")) {
      this.data.type = "partner"
    } else if (this.user.hasPermission("collections.manage")) {
      this.data.type = "personal"
    }
  },
  methods: {
    onTypeChange() {
      this.data.folder_id = null
    },
    onSuccess(result) {
      this.$notification.success(`Collection **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
